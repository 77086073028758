<template>
  <div>

    <section id="knowledge-base-category">
      <b-row class="match-height">
        <b-col
          v-for="(data,index) in categoryList"
          :key="index"
          md="4"
          sm="6"
        >
          <b-card>
            <h6 class="kb-title">
              <feather-icon
                :icon="data.icon"
                size="20"
                class="mr-50"
              />{{ data.titulo }}
            </h6>
            <b-list-group class="list-group-circle mt-2">
              <b-list-group-item
                v-for="(que) in data.tutorialList"
                :key="que.id"
                class="text-body"
                :to="{path: '/tutorial/'+que.link}"
              >
                {{ que.titulo }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

      </b-row>
    </section>

    <section class="pricing-free-trial">
      <b-row class="pt-75 text-center">
        <b-col
          cols="12"
          class="mt-2 mb-3"
        >
          <h2>{{ $t('suporte.titulo') }}</h2>
          <b-card-text>
            {{ $t('suporte.subtitulo') }}
          </b-card-text>
        </b-col>

        <b-col
          lg="10"
          offset-lg="3"
          class="title-suporte mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">

            <div class="text-center text-md-left mt-3">
              <h3
                v-if="empresa && empresa.suporteTelefone"
                class="text-primary"
              >
                {{ empresa.suporteTelefone }}
              </h3>
              <h5 v-if="empresa && empresa.suporteEmail">
                {{ empresa.suporteEmail }}
              </h5>
            </div>

            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
            />
          </div>
        </b-col>
      </b-row>
    </section>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import useAppConfig from '@core/app-config/useAppConfig'
import tutorialStoreModule from './tutorialStoreModule'

const TUTORIAL_STORE_MODULE_NAME = 'tutorial'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardText,
    BImg,
  },

  mixins: [utilsMixin],

  data() {
    return {
      categoryList: [],
    }
  },

  setup() {
    const { empresa } = useAppConfig()
    return {
      empresa,
    }
  },

  created() {
    if (!store.hasModule(TUTORIAL_STORE_MODULE_NAME)) store.registerModule(TUTORIAL_STORE_MODULE_NAME, tutorialStoreModule)
    this.fetchData()
  },

  beforeDestroy() {
    if (store.hasModule(TUTORIAL_STORE_MODULE_NAME)) store.unregisterModule(TUTORIAL_STORE_MODULE_NAME)
  },

  methods: {
    fetchData() {
      store
        .dispatch('tutorial/getCategorias')
        .then(response => {
          this.categoryList = response.data
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

<style lang="scss">
  .pricing-free-trial {
    background-color: rgba(186, 191, 199, 0.12);
    margin-top: 3.5rem;
    margin-bottom: 8rem;
  }
</style>
